import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {InputCheckbox, InputLocation, InputText} from '@evag/ec-react';

import Button from './Button';

import styles from './CampaignForm.module.scss';

const CampaignForm = ({values, onChange, onBlur, setFieldValue}) => {
  const props = name => ({name, value: values[name], onChange, onBlur});
  return (
    <>
      <InputText
        className={styles.text}
        placeholder="Nome completo"
        {...props('name')}
      />
      <InputText
        className={styles.text}
        cleave={{blocks: [0, 2, 9], delimiters: ['(', ') '], numericOnly: true}}
        placeholder="WhatsApp"
        type="tel"
        {...props('phone')}
      />
      <InputText
        className={styles.text}
        placeholder="E-mail"
        type="email"
        {...props('email')}
      />
      <InputLocation
        className={styles.select}
        names={['uf', 'city']}
        setFieldValue={setFieldValue}
        {...{values, onChange, onBlur}}
      />

      <InputCheckbox
        className={styles.checkbox}
        label="Marque aqui para receber novidades do Coletivo Juntos"
        {...props('subscribe')}
      />
      <div><p className={styles.privacidade}>
          <em>Veja nossa <a id="btn-privacidade-form" href="https://juntos.org.br/politica-de-privacidade">Política de Privacidade</a>. 
          Este site é protegido pelo reCAPTCHA e, por isso, a política de privacidade e os termos de serviço do Google também se aplicam.</em></p>
      </div>

      <div className={styles.submeter}>
         <Button id="submit" type="submit" fontSize="16px">Assinar</Button>
      </div>

    </>
  );
};


CampaignForm.initialValues = {
  name: '',
  email: '',
  phone: '',
  uf: '',
  city: '',
  subscribe: false,
};

CampaignForm.validationSchema = Yup.object().shape({
  name: Yup.string().required('O campo Nome é obrigatório.'),
  email: Yup.string().email('Digite um e-mail válido.').required('O campo E-mail é obrigatório.'),
  phone: Yup.string(),
  uf: Yup.string().required('O campo Estado é obrigatório.'),
  city: Yup.string().required('O campo Cidade é obrigatório.'),
  subscribe: Yup.bool(),
});

CampaignForm.propTypes = {
  values: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

CampaignForm.captcha = true;
CampaignForm.element = CampaignForm;

export default CampaignForm;
