import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import "@evag/ec-react/dist/main.css";

import { Form } from '@evag/ec-react';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Img from "gatsby-image";
import SEO from '../components/SEO';
import CountSignatures from '../components/CountSignatures';
import {
  FaFacebook,
  FaFlickr,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/all";

export default function render({ data }) {
  CampaignForm.channelId = data.site.meta.channel;
  const contact = {
    title: `Coletivo Juntos`,
    addressLine1: "Rua Parintins, 58",
    addressLine2:
      "Barra Funda, 01155-020 São Paulo - SP",
    email: "contato@juntos.org.br",
    emailUrl: "mailto:contato@juntos.org.br",
    phone: "",
    phoneUrl: "",
    facebook: "https://facebook.com/juventudeemluta/",
    twitter: "https://twitter.com/coletivojuntos/",
    instagram: "https://instagram.com/coletivojuntos/",
    youtube: "https://instagram.com/coletivojuntos/",
    flicker: "https://www.flickr.com/photos/184024294@N06",
  };

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <nav>
        <div className="wrap">
          <a
            id="btn-home"
            href="https://juntos.org.br"
            title="Página Inicial"
          >
            <Img
              style={{ width: "30%", marginLeft: "16px" }}
              alt="Coletivo Juntos"
              fluid={data.nav.childImageSharp.fluid}
            />
          </a>
        </div>
      </nav>


      <Hero
        background={data.fundo.childImageSharp.fluid}
        logo={data.hero.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap, texto">
          <h3>PASSAPORTE DA VACINA NAS ESCOLAS, JÁ! EM DEFESA DA VIDA DOS ESTUDANTES!</h3>
          <p>
          Desde o início da pandemia estamos passando por momentos tenebrosos na educação. Para além do negacionismo e da política genocida de Bolsonaro, o desmonte na educação é uma tática da política bolsonarista que enxerga as e os estudantes como parte de seus inimigos número 1. Durante a maior parte do tempo da pandemia as/os estudantes vivenciaram inúmeras dificuldades, principalmente em relação ao ensino EAD, que ampliou as desigualdades estruturais, deixando vários estudantes perdidos e ignorando a realidade social que temos em nosso país, ocasionando em uma enorme evasão escolar. 
          </p>

          <p>
          Devido ao avanço da vacinação, que gerou queda no número de pessoas contaminadas e no número de mortes por covid, já no final do ano passado (2021) os estados começaram a realizar um retorno presencial, inicialmente de maneira gradual, das aulas escolares e posteriormente de maneira 100% presencial. 
          </p>

          <p>
          Com o avanço da variante Ômicron, que possui uma taxa de transmissão superior as anteriores, o número de casos de pessoas contaminadas está em constante evolução, principalmente entre pessoas não vacinadas. Uma das medidas necessárias para conter o avanço dos casos é cobrar o passaporte vacinal da população. Contudo, nas vésperas do ínicio do ano letivo nas escolas, apenas 5 estados (Bahia, Ceará, Pará, Paraíba e Piauí) vão solicitar o comprovante vacinal das/os estudantes.
          </p>

          <p>
          Nós, do Juntos!, acreditamos que o cartão de vacinação é necessário frente à campanha antivacina de Bolsonaro e a atual realidade da pandemia. As vacinas possuem sua eficácia comprovada e oferece uma proteção coletiva. Apenas quando todas e todos estiverem devidamente vacinados é que realmente estaremos protegidos do vírus. Por isso, criamos esse abaixo-assinado para que se tenha a exigência do comprovante de vacinação nas escolas.
          </p>

        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Preencha o formulário abaixo para apoiar nossa campanha</h2><br></br>
          <h4 className="signatures-title">Para conter o avanço da variante ômicron é fundamental nossa mobilização por um retorno seguro às aulas! Por isso contamos com teu apoio!</h4>
          <br></br>
          <CountSignatures containerClass={"container-unlimited"} invertedColors={false} channel={data.site.meta.channel} />
          <br></br>
          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>


      <footer className="footer">
        <div className="wrap">
          <div className="content">
            <div className="contact">
              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      id="btn-fb"
                      href={contact.facebook}
                      target="_top"
                      title="Contato no Facebook"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-twitter"
                      href={contact.twitter}
                      target="_top"
                      title="Contato no Twitter"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-instagram"
                      href={contact.instagram}
                      target="_top"
                      title="Contato no Instagram"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-youtube"
                      href={contact.youtube}
                      target="_top"
                      title="Contato no YouTube"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-flickr"
                      href={contact.flicker}
                      target="_top"
                      title="Contato no Flicker"
                    >
                      <FaFlickr />
                    </a>
                  </li>
                </ul>
              </div>
              <p>
                {contact.title}
                <br />
                {contact.addressLine1}
                <br />
                {contact.addressLine2}
              </p>
              <p>
                Copyleft © 2011-2022 Coletivo Juntos
                <br />
                O conteúdo deste site, exceto quando proveniente de outras
                fontes ou onde especificado o contrário, está licenciado sob a
                Creative Commons by-sa 3.0 BR.
                <br />
              </p>
              <p>
                <a
                  id="btn-privacidade"
                  href="https://juntos.org.br/politica-de-privacidade"
                >
                  Política de Privacidade
                </a>
              </p>
            </div>
            <div className="logos">
              <Img
                className="logo"
                alt="Coletivo Juntos"
                fluid={data.juntos.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </footer>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "logoabaixoassinado.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nav: file(relativePath: { eq: "logojuntos1.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fundo: file(relativePath: { eq: "fundojuntos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    juntos: file(relativePath: { eq: "logojuntos.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  
  }
`;
