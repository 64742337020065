import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styles from "./Hero.module.scss";

import Button from "./Button";
import CountSignatures from "./CountSignatures";

const Hero = ({ background, logo, description, channel }) => (
  <div className={styles.container}>
    <Img
      className={styles.image}
      fluid={background}
      style={{ position: false }}
    />
    <div className={styles.hero}>
      <Img className={styles.ratio} fluid={logo} />
    </div>

    <div className="wrap">
      <div className={styles.counter}>
        <p className={styles.description}>{description}</p>
        <CountSignatures channel={channel} />
        <p className={styles.description}>
          <Button id="btn_sign" to="#sign">
            Assine em apoio ao projeto
          </Button>
        </p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  logo: PropTypes.object,
  description: PropTypes.string,
  channel: PropTypes.string,
  title: PropTypes.string,
};

export default Hero;
